<template>
  <v-container fluid>
    <!-- HEADER -->

    <div class="text-center">
      <h2 class="h2">Hrd Inquiry Users</h2>

      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        Information of users
      </p>
    </div>

    <!-- ADD USER -->
    <v-row>
      <v-col v-if="radio == 'GCUser'">
        <v-btn class="mt-6" color="#df6464" dense small outlined @click="Extract()"
          >Extract</v-btn
        >
        <v-text-field class="mt-1" v-model="globalSearch" label="Search" outlined dense />
      </v-col>
      <v-col v-else-if="radio == 'IchijoUser'">
        <v-btn class="mt-6" color="#df6464" dense small outlined @click="Extract()"
          >Extract</v-btn
        >
        <v-text-field class="mt-1" v-model="globalSearch" label="Search" outlined dense />
      </v-col>
      <v-col v-else>
        <v-btn
          class="mt-6 mr-2"
          color="#df6464"
          @click="dialog = !dialog"
          dense
          small
          outlined
          >Add User</v-btn
        >
        <v-btn class="mt-6" color="#df6464" dense small outlined @click="Extract()"
          >Extract</v-btn
        >
        <!-- <div class="pretty p-switch p-fill ml-2 mt-2">
          <input type="checkbox" v-model="toggleCorrection" />
          <div v-if="!toggleCorrection" class="state p-danger">
            <label style="color: #df6464; font-weight: bold"
              >Admin User</label
            >
          </div>
          <div v-else class="state p-danger">
            <label style="color: #df6464; font-weight: bold"
              >Correction User</label
            >
          </div>
        </div> -->
        <v-text-field class="mt-1" v-model="globalSearch" label="Search" outlined dense />
      </v-col>

      <v-col>
        <div
          v-if="
            Position == 'Supervisor' ||
            Position == 'Senior Supervisor' ||
            Position == 'Deputy Manager'
          "
        >
          <v-radio-group v-model="radio" row>
            <v-radio value="CorrectionUser" label="Correction User"></v-radio>
          </v-radio-group>
        </div>
        <div v-else class="pretty p-switch p-fill ml-2 mt-2">
          <v-radio-group v-model="radio" row>
            <v-radio value="AdminUser" label="Admin User"></v-radio>
            <v-radio value="CorrectionUser" label="Correction User"></v-radio>
            <v-radio value="GCUser" label="GC User"></v-radio>
            <v-radio value="IchijoUser" label="Ichijo User"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>

    <!-- DATA TABLE ADMIN USER -->
    <v-row v-if="radio == 'AdminUser'">
      <v-col>
        <v-data-table
          :search="globalSearch"
          :headers="headers"
          :items="getUsers"
          class="elevation-1"
          pagination.sync="pagination"
        >
          <template v-slot:[`item.UserLevel`]="{ item }">
            <v-row>
              <span v-if="item.UserLevel">{{ item.UserLevel }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
          <template v-slot:[`item.Email`]="{ item }">
            <v-row>
              <span v-if="item.Email">{{ item.Email }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
          <template v-slot:[`item.Branch`]="{ item }">
            <v-row>
              <span v-if="item.Branch">{{ item.Branch }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-row>
              <v-btn
                class="mr-3 ma-2 button"
                @click="onEdit(item)"
                color="green accent-3"
                dense
                small
                outlined
                ><v-icon color="green accent-3">mdi-content-save-edit-outline</v-icon>
                Edit</v-btn
              >
              <v-btn
                class="ma-2 button"
                @click="onDelete(item)"
                color="red accent-3"
                dense
                small
                outlined
                ><v-icon color="red accent-3">mdi-trash-can-outline</v-icon> Delete</v-btn
              >
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- DATA TABLE CorrectionUser -->
    <v-row v-if="radio == 'CorrectionUser'">
      <v-col>
        <v-col>
          <v-data-table
            :search="globalSearch"
            :headers="correctionHeader"
            :items="getCorrectionUser"
            class="elevation-1"
            pagination.sync="pagination"
          >
            <template v-slot:[`item.Email`]="{ item }">
              <v-row>
                <span v-if="item.Email">{{ item.Email }}</span>
                <span v-else style="color: red; font-weight: bold">No Record Found!</span>
              </v-row>
            </template>
            <!-- <template v-slot:[`item.Email`]="{ item }">
            <v-row>
              <span v-if="item.Email">{{ item.Email }}</span>
              <span v-else style="color: red; font-weight: bold"
                >No Record Found!</span
              >
            </v-row>
          </template>-->
            <template v-slot:[`item.Branch`]="{ item }">
              <v-row>
                <span v-if="item.Branch">{{ item.Branch }}</span>
                <span v-else style="color: red; font-weight: bold">No Record Found!</span>
              </v-row>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-row>
                <v-btn
                  class="ma-2 button"
                  @click="onEdit(item, true)"
                  color="green accent-3"
                  dense
                  small
                  outlined
                  ><v-icon color="green accent-3">mdi-content-save-edit-outline</v-icon>
                  Edit</v-btn
                >
                <v-btn
                  class="ma-2 button"
                  @click="onDelete(item)"
                  color="red accent-3"
                  dense
                  small
                  outlined
                  ><v-icon color="red accent-3">mdi-trash-can-outline</v-icon>
                  Delete</v-btn
                >
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-col>
    </v-row>

    <!-- DATA TABLE GC USER -->
    <v-row v-if="radio == 'GCUser'">
      <v-col>
        <v-data-table
          :search="globalSearch"
          :headers="GCheaders"
          :items="GetGcUserList"
          class="elevation-1"
          pagination.sync="pagination"
        >
          <template v-slot:[`item.inchijoId`]="{ item }">
            <v-row>
              <span v-if="item.inchijoId">{{ item.inchijoId }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
          <template v-slot:[`item.WorkMailAdd`]="{ item }">
            <v-row>
              <span v-if="item.WorkMailAdd">{{ item.MailAddress }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>

          <template v-slot:[`item.Comp_Name`]="{ item }">
            <v-row>
              <span v-if="item.Comp_Name">{{ item.Comp_Name }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- DATA TABLE ICHIJO USER -->
    <v-row v-if="radio == 'IchijoUser'">
      <v-col>
        <v-data-table
          :search="globalSearch"
          :headers="Ichijoheaders"
          :items="GetIchijoUserList"
          class="elevation-1"
          pagination.sync="pagination"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <!-- DIALOG FOR USER -->
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-5">
        <span
          class="text-lg font-medium text-gray-600"
          style="color: black; font-weight: bold"
        >
          Fill up user information
        </span>
        <br /><br /><v-divider></v-divider>
        <div class="pretty p-switch p-fill ml-2 mt-2">
          <input type="checkbox" v-model="correctionModal" />
          <div v-if="!correctionModal" class="state p-danger">
            <label style="color: #df6464; font-weight: bold">Add Admin User</label>
          </div>
          <div v-else class="state p-danger">
            <label style="color: #df6464; font-weight: bold">Add Correction User</label>
          </div>
        </div>
        <br />
        <span style="color: grey; font-size: 15px">
          User ID <span style="color: red">*</span> </span
        ><br />

        <v-text-field
          v-model="toInsert.EmployeeCode"
          @keyup="FindUser()"
          color="#df6464"
          outlined
          dense
          :disabled="type"
          style="width: 90px"
        ></v-text-field>

        <!-- CORRECTION USER -->
        <div v-if="correctionModal">
          <span style="color: grey; font-size: 15px">
            Name<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.EmployeeName"
            color="#df6464"
            outlined
            dense
            disabled
          ></v-text-field>
          <span style="color: grey; font-size: 15px">
            Department<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.Department"
            color="#df6464"
            outlined
            dense
            disabled
          ></v-text-field>
          <span style="color: grey; font-size: 15px">
            Section<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.Section"
            color="#df6464"
            outlined
            dense
            disabled
          ></v-text-field>
          <span style="color: grey; font-size: 15px">
            Team<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.Team"
            color="#df6464"
            outlined
            dense
            disabled
          ></v-text-field>
          <span style="color: grey; font-size: 15px">
            Position<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.Position"
            color="#df6464"
            outlined
            dense
            disabled
          ></v-text-field>
          <span style="color: grey; font-size: 15px"> Email </span>
          <v-text-field
            v-model="toInsert.Email"
            color="#df6464"
            outlined
            dense
          ></v-text-field>
          <span style="color: grey; font-size: 15px"> Signature </span>
          <v-text-field
            v-model="toInsert.Signature"
            color="#df6464"
            outlined
            dense
          ></v-text-field>
          
          
          
          
        </div>
        

        <!-- NORMAL USER -->
        <div v-if="!correctionModal">
          <span style="color: grey; font-size: 15px">
            Name<span style="color: red">*</span>
          </span>
          <v-text-field
            v-model="toInsert.FullName"
            color="#df6464"
            outlined
            dense
          ></v-text-field>
          <span style="color: grey; font-size: 15px"> Email </span>
          <v-text-field
            v-model="toInsert.Email"
            color="#df6464"
            outlined
            dense
            :rules="emailRules"
          ></v-text-field>
          <span style="color: grey; font-size: 15px"> User Status </span>
          <v-select
            :items="userStatus"
            v-model="toInsert.UserLevel"
            color="#df6464"
            outlined
            dense
          ></v-select>
          <span style="color: grey; font-size: 15px"> Branch </span>
          <v-text-field
            v-model="toInsert.Branch"
            color="#df6464"
            outlined
            dense
          ></v-text-field>
          <div v-if="!type">
            <span style="color: grey; font-size: 15px">
              Password<span style="color: red">*</span>
            </span>
            <v-text-field
              v-model="toInsert.Password"
              color="#df6464"
              outlined
              dense
              type="password"
            ></v-text-field>
          </div>
        </div>

        <v-btn @click="registerUser" color="success" block outlined>Submit</v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import md5 from "md5";
import axios from "axios";
import objects from "../functions/alert";
import store from "@/store";

import UsersTable from "../functions/httpRequest/ExternalConnection/UsersTable";
import AllConnection from "../functions/httpRequest/hrdappsAPI/allConnection";
import CorrectionTable from "../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import Excel from "exceljs";
import { saveAs } from "file-saver";
// import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      globalSearch: "",
      dialogView: false,
      Position: store.state.userinfo.Position,
      radio: "CorrectionUser",
      dialog: false,
      type: false,
      toggleCorrection: false,
      correctionModal: true,
      toInsert: {},
      getUsers: [],
      getCorrectionUser: [],
      GetGcUserList: [],
      GetIchijoUserList: [],
      userStatus: [
        "HRD Admin",
        "HRD User",
        "Ichijo Leader",
        "Ichijo Member",
        "GC Leader",
        "GC Member",
      ],
      headers: [
        { text: "UserID", value: "EmployeeCode" },
        { text: "Status", value: "UserLevel" },
        { text: "Name", value: "Fullname" },
        { text: "Mail Address", value: "Email" },
        { text: "Branch", value: "Branch" },
        { text: "Action", value: "Action" },
      ],
      correctionHeader: [
        { text: "EmployeeCode", value: "EmployeeCode" },
        { text: "EmployeeName", value: "EmployeeName" },
        { text: "Email", value: "Email" },
        { text: "Department", value: "Department" },
        { text: "Section", value: "Section" },
        { text: "Team", value: "Team" },
        { text: "Position", value: "Position" },
        { text: "Action", value: "Action" },
      ],
      GCheaders: [
        { text: "ID", value: "UserId" },
        { text: "Status", value: "account_status" },
        { text: "Name", value: "Name" },
        { text: "Account Type", value: "account_type" },
        { text: "Mail Address", value: "MailAddress" },
        { text: "Company Name", value: "company_group" },
      ],
      Ichijoheaders: [
        { text: "EmployeeCode", value: "EmployeeCode" },
        { text: "Active", value: "isActive" },
        { text: "Name", value: "EmployeeName" },
        { text: "email", value: "mail_address" },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  watch: {
    // toggleCorrection(newVal) {
    //   if (newVal) this.CorrectionUsers();
    // },
    // WATCH FOR DIALOG CHANGES
    dialog() {
      if (!this.dialog) {
        this.type = false;
        this.toInsert = {};
      }
    },
  },
  created() {
    // CALL FUNCTION IN CREATED
    this.getUser();
    this.CorrectionUsers();
    this.GetGcUser();
    this.GetIchijoUser();
  },
  methods: {
    // TODO ExtractFunction
    Extract() {
      if (this.radio == "AdminUser") {
        const firstProcess = () => {
          return new Promise((resolve) => {
            const headers = {
              EmployeeCode: "User ID",
              UserLevel: "Status",
              Fullname: "Name",
              Email: "Mail Address",
              Branch: "Branch",
            };
            const excel_data = [...this.getUsers];
            const workbook = new Excel.Workbook();
            const DetailedSheet = workbook.addWorksheet("Detailed");

            DetailedSheet.columns = [
              { key: "A1", width: 15 },
              { key: "B1", width: 25 },
              { key: "C1", width: 35 },
              { key: "D1", width: 35 },
              { key: "E1", width: 25 },
            ];

            excel_data.unshift(headers);
            // console.log(filterExtractedDate);

            excel_data.forEach((value, index) => {
              DetailedSheet.getRow(index).height = 25;

              let column = [
                { key: "A", value: value.EmployeeCode },
                {
                  key: "B",
                  value: value.UserLevel ? value.UserLevel : "No Record Found!",
                },
                { key: "C", value: value.Fullname },
                {
                  key: "D",
                  value: value.Email ? value.Email : "No Record Found!",
                },
                {
                  key: "E",
                  value: value.Branch ? value.Branch : "No Record Found!",
                },
              ];

              let borders = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              let headerFont = { name: "Arial", size: 10, bold: true };
              let Font = { name: "Arial", size: 10, bold: false };

              column.forEach((letter) => {
                DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).font =
                  index === 0 ? headerFont : Font;

                if (letter.value === "No Record Found!") {
                  DetailedSheet.getCell(`${letter.key}${index + 1}`).font = {
                    name: "Arial",
                    size: 10,
                    bold: true,
                    color: { argb: "FFFF0000" },
                  };
                }

                DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
              });
            });

            const buffer = workbook.xlsx.writeBuffer();
            resolve(buffer);
          });
        };
        firstProcess().then((val) => {
          const blob = new Blob([val], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `Extraction Inquiry Users/AdminUser(${moment().format("YYYY-MM-DD")})`
          );
        });
      }

      // CORRECTIONUSER
      if (this.radio == "CorrectionUser") {
        const firstProcess = () => {
          return new Promise((resolve) => {
            const headers = {
              EmployeeCode: "Employee Code",
              EmployeeName: "Employee Name",
              Email: "Email",
              Department: "Department",
              Team: "Team",
              Section: "Section",
              Position: "Position",
            };
            const excel_data = [...this.getCorrectionUser];
            const workbook = new Excel.Workbook();
            const DetailedSheet = workbook.addWorksheet("Detailed");

            DetailedSheet.columns = [
              { key: "A1", width: 15 },
              { key: "B1", width: 35 },
              { key: "C1", width: 35 },
              { key: "D1", width: 35 },
              { key: "E1", width: 35 },
              { key: "F1", width: 35 },
              { key: "G1", width: 35 },
            ];

            excel_data.unshift(headers);
            // console.log(filterExtractedDate);

            excel_data.forEach((value, index) => {
              DetailedSheet.getRow(index).height = 25;

              let column = [
                { key: "A", value: value.EmployeeCode },
                { key: "B", value: value.EmployeeName },
                {
                  key: "C",
                  value: value.Email ? value.Email : "No Record Found!",
                },
                { key: "D", value: value.Department },
                { key: "E", value: value.Department },
                { key: "F", value: value.Section },
                { key: "G", value: value.Position },
              ];

              let borders = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              // let alignment = { wrapText: true };
              let headerFont = { name: "Arial", size: 10, bold: true };
              let Font = { name: "Arial", size: 10, bold: false };

              column.forEach((letter) => {
                DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).font =
                  index === 0 ? headerFont : Font;

                if (letter.value === "No Record Found") {
                  DetailedSheet.getCell(`${letter.key}${index + 1}`).font = {
                    name: "Arial",
                    size: 10,
                    bold: true,
                    color: { argb: "FFFF0000" },
                  };
                }

                DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
              });
            });

            const buffer = workbook.xlsx.writeBuffer();
            resolve(buffer);
          });
        };
        firstProcess().then((val) => {
          const blob = new Blob([val], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `Extraction Inquiry Users/CorrectionUser(${moment().format("YYYY-MM-DD")})`
          );
        });
      }

      //GC USER
      if (this.radio == "GCUser") {
        const firstProcess = () => {
          return new Promise((resolve) => {
            const headers = {
              UserId: "ID",
              account_status: "Status",
              Name: "Name",
              account_type: "Account Type",
              MailAddress: "Mail Address",
              company_group: "Company Name",
            };
            const excel_data = [...this.GetGcUserList];
            const workbook = new Excel.Workbook();
            const DetailedSheet = workbook.addWorksheet("Detailed");
            // let indexCell = { detailed: 1, summary: 1 };

            DetailedSheet.columns = [
              { key: "A1", width: 15 },
              { key: "B1", width: 15 },
              { key: "C1", width: 25 },
              { key: "D1", width: 15 },
              { key: "E1", width: 35 },
              { key: "F1", width: 15 },
            ];
            excel_data.unshift(headers);
            // console.log(filterExtractedDate);

            excel_data.forEach((value, index) => {
              DetailedSheet.getRow(index).height = 25;

              let column = [
                { key: "A", value: value.UserId },
                { key: "B", value: value.account_status },
                { key: "C", value: value.Name },
                { key: "D", value: value.account_type },
                { key: "E", value: value.MailAddress },
                { key: "F", value: value.company_group },
              ];

              let borders = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              // let alignment = { wrapText: true };
              let headerFont = { name: "Arial", size: 10, bold: true };
              let Font = { name: "Arial", size: 10, bold: false };

              column.forEach((letter) => {
                // DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment =
                //   letter.key == "M" ? alignment : {};
                DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).font = headers
                  ? headerFont
                  : Font;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
                if (index + 1 > 1) {
                  let heightCell = 0;
                  if (letter.key == "S") {
                    let kahitano = letter.value.split("\n");
                    heightCell = kahitano.length * 11;
                    DetailedSheet.getRow(index + 1).height = heightCell;
                  }
                }
                if (letter.key !== "S") {
                  DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                  };
                }
              });
            });

            const buffer = workbook.xlsx.writeBuffer();
            resolve(buffer);
          });
        };
        firstProcess().then((val) => {
          const blob = new Blob([val], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `Extraction Inquiry Users/GCUser(${moment().format("YYYY-MM-DD")})`
          );
        });
      }

      //Ichijo USER
      if (this.radio == "IchijoUser") {
        const firstProcess = () => {
          return new Promise((resolve) => {
            const headers = {
              EmployeeCode: "Employee Code",
              isActive: "Status",
              EmployeeName: "Account Type",
              mail_address: "Mail Address",
            };
            const excel_data = [...this.GetIchijoUserList];
            const workbook = new Excel.Workbook();
            const DetailedSheet = workbook.addWorksheet("Detailed");
            // let indexCell = { detailed: 1, summary: 1 };

            DetailedSheet.columns = [
              { key: "A1", width: 15 },
              { key: "B1", width: 15 },
              { key: "C1", width: 25 },
              { key: "D1", width: 35 },
            ];
            excel_data.unshift(headers);
            // console.log(filterExtractedDate);

            excel_data.forEach((value, index) => {
              DetailedSheet.getRow(index).height = 25;

              let column = [
                { key: "A", value: value.EmployeeCode },
                { key: "B", value: value.isActive },
                { key: "C", value: value.EmployeeName },
                { key: "D", value: value.mail_address },
              ];

              let borders = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              // let alignment = { wrapText: true };
              let headerFont = { name: "Arial", size: 10, bold: true };
              let Font = { name: "Arial", size: 10, bold: false };

              column.forEach((letter) => {
                // DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment =
                //   letter.key == "M" ? alignment : {};
                DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).font = headers
                  ? headerFont
                  : Font;
                DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
                if (index + 1 > 1) {
                  let heightCell = 0;
                  if (letter.key == "S") {
                    let kahitano = letter.value.split("\n");
                    heightCell = kahitano.length * 11;
                    DetailedSheet.getRow(index + 1).height = heightCell;
                  }
                }
                if (letter.key !== "S") {
                  DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                  };
                }
              });
            });

            const buffer = workbook.xlsx.writeBuffer();
            resolve(buffer);
          });
        };
        firstProcess().then((val) => {
          const blob = new Blob([val], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `Extraction Inquiry Users/IchijoUser(${moment().format("YYYY-MM-DD")})`
          );
        });
      }
    }, //End of Extract

    //****************************//
    //     EDIT USER FUNCTION     //
    //****************************//
    onEdit(val, correctionUser) {
      if (correctionUser == true) {
        console.log(val);
        this.type = false;
        this.toInsert.EmployeeCode = val.EmployeeCode;
        this.toInsert.FullName = val.Fullname;
        this.toInsert.Department = val.DepartmentName;
        this.toInsert.Section = val.SectionName;
        this.toInsert.Team = val.TeamName;
        this.toInsert.Position = val.Position;
        this.toInsert.Email = val.Email;
        this.toInsert.Signature = val.Signature;
        this.dialog = true;
      } else {
        // INSERT USER INFO FOR EDITING
        this.type = true;
        this.toInsert.EmployeeCode = val.EmployeeCode;
        this.toInsert.FullName = val.Fullname;
        this.toInsert.Email = val.Email;
        this.toInsert.Branch = val.Branch;
        this.dialog = true;

      }
      // this.additionalEdit = true,
      // this.toInsert.EmployeeCode = val.EmployeeCode;
      // this.toInsert.FullName = val.Fullname;
      // this.toInsert.Department = val.DepartmentName;
      // this.toInsert.Section = val.SectionName;
      // this.toInsert.Team = val.TeamName;
      // this.toInsert.Position = val.Position;
      // this.toInsert.Email = val.Email;
      // this.toInsert.Signature = val.Signature;

    },

    //****************************//
    //   DELETE USER FUNCTION     //
    //****************************//

    onDelete(val) {
      // const EmployeeCode = val.EmployeeCode;
      // CONFIRM TO DELETE USER
      this.$confirm({
        title: "User",
        message: "Confirm to delete",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: async (confirm) => {
          if (confirm) {
            for (let x in this.getCorrectionUser)
            if (this.getCorrectionUser[x].id == val.id)
              this.getCorrectionUser.splice(x, 1);
            // UsersTable.Delete(EmployeeCode).then(() => {
              this.$toast.success("Deleted Successfully", objects.alert);
              this.getUser();
            // });
          }
        },
      });
    },
    //****************************//
    //    GET ALL INQUIRY USER    //
    //****************************//

    getUser() {
      UsersTable.GetAllUser().then((res) => (this.getUsers = res.data));
    },
    //****************************//
    //     GET SPECIFIC USER      //
    //****************************//
    FindUser() {
      this.$set(this.toInsert, !this.correctionModal ? "FullName" : "EmployeeName", "");
      if (this.toInsert.EmployeeCode.length === 5) {
        AllConnection.HRDSpecificMasterlist(this.toInsert.EmployeeCode)
          .then((res) => {
            this.$set(
              this.toInsert,
              !this.correctionModal ? "FullName" : "EmployeeName",
              res.EmployeeName
            );
            this.toInsert.Department = res.DepartmentName;
            this.toInsert.Section = res.SectionName;
            this.toInsert.Team = res.TeamName;
            this.toInsert.Position = res.DesignationName;
          })
          .catch(() => this.$toast.error("No record found", objects.alert));
      }
    },
    //****************************//
    //     ADD USER FUNCTION      //
    //****************************//

    async registerUser() {
      // CHECK FOR ALL REQUIRED FIELDS
      if (
        !this.toInsert.EmployeeCode ||
        !this.toInsert[!this.correctionModal ? "FullName" : "EmployeeName"] ||
        this.correctionModal
          ? ""
          : !this.toInsert.Password
      )
        return this.$toast.error("Fill up all fields", objects.alert);
      // FOR EDIT USER
      if (this.type) {
        // UPDATE USER TO DATABASE
        UsersTable.Update(this.toInsert).then(() => {
          this.dialog = false;
          this.$toast.success("Updated Successfully", objects.alert);
          this.getUser();
        });
      } else {
        if (this.correctionModal) {
          CorrectionTable.GetSpecificUser(this.toInsert.EmployeeCode).then(
            async (res) => {
              const record = res.data;
              if (record.length)
                return this.$toast.error("Employee Already Registered", objects.alert);
              delete this.toInsert.Password;
              await CorrectionTable.Insert(this.toInsert).then(() => {
                this.dialog = false;
                this.$toast.success("Registered Successfully", objects.alert);
                this.CorrectionUsers();
              });
            }
          );
        } else {
          // FOR ADD NEW USER
          await UsersTable.GetSpecificUser(this.toInsert.EmployeeCode).then(
            async (res) => {
              //   CHECK EXISTING USER
              if (res.data != "No Data Found!")
                return this.$toast.error("Employee Already Registered", objects.alert);
              //   CHECK EXISTING EMAIL
              await UsersTable.getSpecificEmail(this.toInsert.Email).then(async (res) => {
                if (res.data != "No Data Found!")
                  return this.$toast.error("Email Already Registered", objects.alert);
                this.dialog = false;

                this.toInsert.Password = md5(this.toInsert.Password);
                this.toInsert.Type = "Correction";

                //  INSERT USER TO DATABASE
                await UsersTable.Post(this.toInsert).then(() => {
                  this.$toast.success("Registered Successfully", objects.alert);
                  this.getUser();
                });
              });
            }
          );
        }
      }
    },

    CorrectionUsers() {
      CorrectionTable.Get().then((res) => {
        this.getCorrectionUser = res.data;
      });
    },
    GetGcUser() {
      // const BaseUrlUSERS ="https://1ze9v428i9.execute-api.us-east-2.amazonaws.com/prod";
      const BaseUrlUSERS =
        "https://9rk5no11y2.execute-api.us-east-2.amazonaws.com/prod/users/AllUsers";
      // const apiKeyUSERS = {headers: { "x-api-key": "JUe7mHXOhR6ziaSDHXUDJ310nTfdsH8s1ph44z2q" },};
      const apiKeyUSERS = {
        headers: { "x-api-key": "yrzZIOXHw5M3creXNV63amuXO5pEAIxJubqiDn70" },
      };
      // axios.get(`${BaseUrlUSERS}/getUsers`, apiKeyUSERS)
      axios.get(`${BaseUrlUSERS}`, apiKeyUSERS).then((res) => {
        let getGC = res.data.filter((rec) => {
          if (rec.company_group != "HRD") return rec;
        });
        this.GetGcUserList = Object.assign(getGC);
      });
    },
    GetIchijoUser() {
      const BaseUrlUSERS =
        "https://347iju22f5.execute-api.ap-southeast-1.amazonaws.com/prod";
      const apiKeyUSERS = {
        headers: { "x-api-key": "b1dWmUWuH9b5CxASNnJg5p9KKNnf9JS2P4Q1gd77" },
      };
      axios.get(`${BaseUrlUSERS}/Transaction/ListofUsers`, apiKeyUSERS).then((res) => {
        console.log(res.data);
        // this.GetIchijoUserList = res.data;
        let getIchijo = res.data.filter((rec) => {
          if (rec.isActive == 1) {
            rec.isActive = "Active";
          }
          return rec;
        });
        //  this.GetGcUserList = Object.assign(getGC);

        this.GetIchijoUserList = getIchijo;
      });
    },
  },
};
</script>
